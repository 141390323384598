.App {
  text-align: center;
}

.button {
   margin: 5px;
}

.button:hover {
  transform: scale(1.15); 
} 

.loginBox {
  position: absolute;
  left: 30%;
  top: 30%;

  width: 30%;   
  border: 3px solid green;  
  padding: 10px;
  text-align: center;

  font-size: large;
  justify-content: center
}

.legend {
  position: absolute;
  right: 0%;
  top: 0%;

  border: 3px solid green;  
  padding: 10px;
  text-align: center;

  font-size: large;
  justify-content: center

}

.buttons {
  position: absolute;
  right: 5%;
  bottom: 5%;
}
.buttons button {
  padding: 15px 32px;
  font-size: 20px;
}

.loginButton {   
   display: inline-block;
}

.infoPanel {
  position: absolute;  
  right: 0%;
  top: 0%; 
  cursor: default;
}


.annInfo {
  position: absolute; 
  background-color: aquamarine; 
  left: 0%;
  top: 0%; 
  cursor: default;
}



.pointInfo {
    position: absolute; 
    background-color: rgb(199, 253, 5); 
    font-size: 36px;
    left: 0px;
    top: 0px; 
    cursor: default;
  }
  
  


 .rv-button {
    margin: 10px;
    font-size: 24px;
 } 
 .rv-form {
    margin: 20px;
    font-size: 24px;
 } 

 .coach-video {
    margin-left: 10px;
 }


 .pe-form {
    margin: 10px;
    font-size: 14px;
    vertical-align: middle;
 }


 .pe-input {
    margin: 10px;
    font-size: 12px;
    vertical-align: middle;
 }

 .w500 {
    width : 500px;
 }
